import { getDatalayerValue } from "../../assets/js/utils/datalayer.js";
import { mediaQuery } from "../../assets/js/utils/media.js";

class SucheGlobalWrapper extends HTMLElement {
    constructor() {
        super();
        __webpack_public_path__ = `${this.dataset["assetBaseUri"]}/suche-global/`;
        this.events = ["click", "focus"];
        this.sucheGlobal = this.querySelector("suche-global");
        this.searchLoaded = false;
        this.addListener();
        this.setSuchbegriffInSuchbox();
    }

    get isLarge() {
        return mediaQuery("min-width", 768);
    }

    disconnectedCallback() {
        this.removeListener();
    }

    addListener() {
        for (const event of this.events) {
            this.addEventListener(event, this, { capture: true });
        }
        document.addEventListener("kategorieseite-geladen", (event) => {
            this.handleKategorieseiteGeladen(event);
        }, { once: true })
    }

    removeListener() {
        for (const event of this.events) {
            this.removeEventListener(event, this, { capture: true });
        }
    }

    handleEvent(event) {
        this[`handle${event.type}`](event);
    }

    async handleKategorieseiteGeladen(event) {
        const { kategorieLabel, kategorieSuchparameter } = event.detail;
        try {
            const { KategorieFilter } = await import(/* webpackChunkName: "kategorie-filter" */ "./kategorie-filter.js");
            const kategoriefilter = new KategorieFilter(kategorieLabel, kategorieSuchparameter);
            const template = this.querySelector("#kategorie-filter");
            const clone = template.content.cloneNode(true);
            kategoriefilter.appendChild(clone);
            template.insertAdjacentElement("beforebegin", kategoriefilter);
        } catch (error) {
            console.error("SucheGlobalWrapper", "handleKategorieseiteGeladen", error);
        }
    }

    async handleclick(event) {
        if (this.isLarge) {
            return;
        }
        try {
            await this.loadSearch(event);
        } catch (error) { }
    }

    async handlefocus(event) {
        if (!this.isLarge) {
            return;
        }
        try {
            await this.loadSearch(event);
        } catch (error) { }
    }

    async loadSearch(event) {
        const { target } = event;
        if (this.searchLoaded) {
            return;
        }
        const { action } = target.dataset;
        if (action === "search" || action === "autofill-search" || action === "open-overlay") {
            try { 
                await import(/* webpackChunkName: "suche-global" */ "./suche-global.js");
                if (action !== "autofill-search") {
                    await this.sucheGlobal.initVorschlaege();
                }
                this.searchLoaded = true;
            } catch (error) {
                console.error("SucheGlobalWrapper", "loadSearch", error);
            }
        }
    }

    async setSuchbegriffInSuchbox() {
        const suchbegriff = getDatalayerValue("suchbegriff");
        if (!suchbegriff) return;
        try {
            const event = {
                target: {
                    dataset: {
                        action: "autofill-search"
                    }
                }
            }
            await this.loadSearch(event);
            for (const input of this.querySelectorAll(".eingabefeld")) {
                input.value = suchbegriff;
                this.sucheGlobal.showResetButton();
            }
        } catch (error) { }
    }
}

if (!customElements.get("suche-global-wrapper")) {
    customElements.define("suche-global-wrapper", SucheGlobalWrapper);
}
